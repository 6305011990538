import { LEGACY_HOST, REPORTING_HOST } from '@/environment';
import constants from '@/constants';
import storage from '@/storage';
import urlJoin from 'url-join';
import { lowerCase } from 'lodash';

const LEGACY_SELECT_ACCOUNT_ROUTE = '/account/selectaccount';

function getActiveJurisdiction() {
  return lowerCase(storage.getItem(constants.JURISDICTION_ID) || constants.DEFAULT_JURISDICTION);
}

export const getLegacyBaseUrl = (returnUrl: string = ''): string => {
  const accountId = storage.getItem(constants.ACCOUNT_ID) || '';

  return urlJoin(LEGACY_HOST, getActiveJurisdiction(), LEGACY_SELECT_ACCOUNT_ROUTE, accountId, `?returnUrl=${encodeURIComponent(returnUrl)}`);
};

export default {
  accountLogin(): string { return urlJoin(LEGACY_HOST, getActiveJurisdiction(), '/account/login') },
  accountSettings(): string { return getLegacyBaseUrl('Account/Manage') },
  additives(): string { return getLegacyBaseUrl('Grow/Additives') },
  admin(): string { return getLegacyBaseUrl('admin' ) },
  auditLog(): string { return getLegacyBaseUrl('admin/auditlog') },
  batch(): string { return getLegacyBaseUrl('Batch' ) },
  conversions(): string { return getLegacyBaseUrl('Inventory/Conversions') },
  createSample(): string { return getLegacyBaseUrl('SalesOrders/CreateSample') },
  dashboard(): string { return getLegacyBaseUrl('Dashboard' ) },
  employee(): string { return getLegacyBaseUrl('admin/employee') },
  feedback(): string { return 'https://getgrowflow.typeform.com/to/FHZL3U'; },
  fixer(): string { return getLegacyBaseUrl('fixer' ) },
  grow(): string { return getLegacyBaseUrl('Grow' ) },
  help(): string { return 'https://help.growflow.com/en/'; },
  growFlowUniversity(): string { return 'https://www.growflow.com/university'; },
  import(): string { return getLegacyBaseUrl('import') },
  inboundOrders(): string { return getLegacyBaseUrl('SalesInbound') },
  insights(organizationKey: string): string { return urlJoin(REPORTING_HOST, organizationKey) },
  inventory(): string { return getLegacyBaseUrl('Inventory') },
  labels(): string { return getLegacyBaseUrl('labels') },
  newOrder(): string { return getLegacyBaseUrl('SalesOrders/NewOrder') },
  newPreorder(): string { return getLegacyBaseUrl('SalesOrders/NewPreOrder') },
  packageDetails(packageId: number | string): string { return getLegacyBaseUrl(`/Inventory?d=${packageId}`) },
  plants(): string { return getLegacyBaseUrl('Plants') },
  printnode(): string { return getLegacyBaseUrl('printer/printnode') },
  products(): string { return getLegacyBaseUrl('admin/product') },
  qaResults(): string { return getLegacyBaseUrl('admin/QAResults') },
  referer(): string { return 'https://growflow.com/refer/'; },
  rooms(): string { return getLegacyBaseUrl('admin/room') },
  salesHistory(): string { return getLegacyBaseUrl('SalesOrders/History') },
  salesOrders(): string { return getLegacyBaseUrl('SalesOrders') },
  store(): string { return getLegacyBaseUrl('/StoreAdmin') },
  strain(): string { return getLegacyBaseUrl('admin/strain') },
  userSettings(): string { return getLegacyBaseUrl('Manage') },
  vehicles(): string { return getLegacyBaseUrl('admin/vehicle') },
  vendors(): string { return getLegacyBaseUrl('Vendors') },
};
